import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableView } from '@x/common/data';
import { PromptDialogService } from '@x/dashboard/dialog';
import { IVoucherProgramRowObject, VoucherProgramService } from '@x/ecommerce/domain-client';
import { VoucherProgramFilterInput } from '@x/schemas/ecommerce';
import { firstValueFrom } from 'rxjs';
import { VoucherProgramDialogService } from '../../services/voucher-program-dialog.service';

@Component({
  selector: 'x-voucher-program-table',
  templateUrl: './voucher-program-table.component.html',
  styleUrls: ['./voucher-program-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class VoucherProgramTableComponent implements OnInit {
  @Input()
  view: DataTableView<IVoucherProgramRowObject, VoucherProgramFilterInput, any, number>;

  constructor(
    private voucherProgramService: VoucherProgramService,
    private voucherProgramDialogService: VoucherProgramDialogService,
    private snackbar: MatSnackBar,
    private promptDialog: PromptDialogService,
  ) {}

  ngOnInit(): void {}

  async archive(row: IVoucherProgramRowObject) {
    const result = await firstValueFrom(
      this.promptDialog.confirm({
        title: 'Archive Voucher Program',
        message: 'Are you sure you want to archive the voucher program?',
      }),
    );

    if (!result) return;

    this.view
      .mutateRow(row.id, () => this.voucherProgramService.archive(row.id))
      .subscribe(() => {
        this.snackbar.open(`Voucher Program archived`, 'Ok', { duration: 5000 });
      });
  }

  edit(row: IVoucherProgramRowObject): void {
    this.voucherProgramDialogService
      .openVoucherProgramCreateDialog(row.id)
      .afterClosed()
      .subscribe((r) => {
        this.snackbar.open(`Voucher Program updated`, 'Ok', { duration: 5000 });
        this.view.refresh();
      });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VoucherService } from '@x/ecommerce/domain-client';
import {
  UserAutocompleteDatasource,
  VoucherProgramAutocompleteDatasource,
} from '@x/ecommerce/domain-data';
import { CreateVoucherInput, VoucherProgramObject } from '@x/schemas/ecommerce';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface VoucherDialogData {
  voucherId?: number;
}

export interface VoucherDialogResult {
  data?: { id: number };
}

@Component({
  selector: 'x-voucher-dialog',
  templateUrl: './voucher-dialog.component.html',
  styleUrls: ['./voucher-dialog.component.scss'],
  providers: [VoucherProgramAutocompleteDatasource, UserAutocompleteDatasource],
})
export class VoucherDialogComponent implements OnInit {
  //unsubscribe
  private _destroy$ = new Subject<void>();

  voucherForm = new UntypedFormGroup({
    value: new UntypedFormControl(null, [Validators.required, Validators.min(1)]),
    voucherPrograms: new UntypedFormControl('', Validators.required),
    customerUsers: new UntypedFormControl('', Validators.required),
    reason: new UntypedFormControl('', Validators.required),
  });

  currency: string = 'ZAR';
  disabled: boolean = false;

  constructor(
    public dialog: MatDialogRef<VoucherDialogComponent>,
    private voucherService: VoucherService,
    public voucherProgramDataSource: VoucherProgramAutocompleteDatasource,
    public userAutocompleteDatasource: UserAutocompleteDatasource,
    @Inject(MAT_DIALOG_DATA) public data: VoucherDialogData,
    private snackbar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.voucherForm.get('voucherPrograms')?.valueChanges.subscribe((v: VoucherProgramObject) => {
      this.voucherForm.get('value')?.setValue(v.value);
      this.currency = v.currency;
    });
  }

  submit() {
    this.voucherForm.updateValueAndValidity();

    if (this.voucherForm.invalid) return;

    this.disabled = true;

    const form = this.voucherForm.value;

    const data: CreateVoucherInput = {
      value: form.value,
      userId: form.customerUsers.id,
      programId: form.voucherPrograms.id,
      reason: form.reason,
    };

    this.voucherService
      .create(data)
      .pipe(
        takeUntil(this._destroy$),
        tap((data) => {
          this.snackbar.open(`Voucher Created`, 'Ok', {
            duration: 5000,
          });
          this.dialog.close({ data });
        }),
      )
      .subscribe();
  }
}

<h2 mat-dialog-title>{{ verb }} Voucher Program</h2>
<form
  mat-dialog-content
  [formGroup]="voucherProgramForm"
  id="voucher_program_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>
          <fa-icon icon="signature"></fa-icon>
          Name
        </mat-label>
        <input
          matInput
          type="text"
          formControlName="name"
          required
          autocomplete="disabled"
          maxlength="60"
        />
      </mat-form-field>

      <mat-form-field class="mt-4">
        <mat-label>
          <fa-icon icon="circle"></fa-icon>
          Voucher Provider
        </mat-label>
        <mat-select required formControlName="voucherProvider">
          <mat-option
            *ngFor="let voucherProvider of voucherProviders"
            value="{{ voucherProvider.id }}"
          >
            {{ voucherProvider.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="mt-4">
        <mat-label>
          <fa-icon icon="money-bill"></fa-icon>
          Currency
        </mat-label>
        <mat-select required formControlName="currency">
          <mat-option *ngFor="let currency of currencies" value="{{ currency }}">
            {{ currency }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          <fa-icon icon="coins"></fa-icon>
          Value
        </mat-label>
        <x-currency-input-control
          formControlName="value"
          [currency]="currency"
          required
        ></x-currency-input-control>
      </mat-form-field>

      <mat-form-field appearance="fill" class="mt-4">
        <mat-label>
          <fa-icon icon="list"></fa-icon>
          Select Product Variants
        </mat-label>
        <x-model-autocomplete-chips
          formControlName="variants"
          [dataSource]="productVariantDataSource"
          placeholder="Search product variants..."
          [displayWith]="productVariantDataSource.displayFn"
        ></x-model-autocomplete-chips>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button mat-button type="submit" color="primary" form="voucher_program_form">
    {{ verb }}
  </button>
</div>

<h2 mat-dialog-title>Cancel Voucher</h2>
<form
  mat-dialog-content
  [formGroup]="voucherCancelForm"
  id="voucher_cancel_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>
          <fa-icon icon="circle-question"></fa-icon>
          Reason
        </mat-label>
        <input
          matInput
          type="text"
          formControlName="reason"
          required
          autocomplete="disabled"
          maxlength="256"
        />
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Close</button>
  <button mat-button type="submit" color="primary" form="voucher_cancel_form">
    Cancel Voucher
  </button>
</div>

<h2 mat-dialog-title>Create Voucher</h2>
<form
  mat-dialog-content
  [formGroup]="voucherForm"
  id="voucher_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field appearance="fill" class="mt-4">
        <mat-label>
          <fa-icon icon="list"></fa-icon>
          Select Voucher Program
        </mat-label>
        <x-model-autocomplete
          formControlName="voucherPrograms"
          [dataSource]="voucherProgramDataSource"
          placeholder="Search voucher programs..."
          [displayWith]="voucherProgramDataSource.displayFn"
        ></x-model-autocomplete>
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          <fa-icon icon="coins"></fa-icon>
          Value
        </mat-label>
        <x-currency-input-control
          formControlName="value"
          [currency]="currency"
          required
        ></x-currency-input-control>
      </mat-form-field>

      <mat-form-field appearance="fill" class="mt-4">
        <mat-label>
          <fa-icon icon="list"></fa-icon>
          Select Customer
        </mat-label>
        <x-model-autocomplete
          formControlName="customerUsers"
          [dataSource]="userAutocompleteDatasource"
          placeholder="Search Customers..."
          [displayWith]="userAutocompleteDatasource.displayFn"
        ></x-model-autocomplete>
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          <fa-icon icon="circle-question"></fa-icon>
          Reason
        </mat-label>
        <input
          matInput
          type="text"
          formControlName="reason"
          required
          autocomplete="disabled"
          maxlength="256"
        />
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()" disabled="{{ disabled }}">
    Cancel
  </button>
  <button mat-button type="submit" color="primary" form="voucher_form" disabled="{{ disabled }}">
    <fa-icon *ngIf="disabled" icon="hourglass"></fa-icon>
    Create
  </button>
</div>

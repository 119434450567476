<x-data-table [view]="view">
  <ng-template xDataColumnDef="id" let-row>
    {{ row.id }}
  </ng-template>
  <ng-template xDataColumnDef="code" let-row>
    <strong>
      {{ row.code }}
    </strong>
  </ng-template>
  <ng-template xDataColumnDef="value" let-row>
    {{ row.value | money: row.currency }}
  </ng-template>

  <ng-template xDataColumnDef="customer" let-row>
    <x-user-pill
      *ngIf="row.user"
      [user]="row.user"
      [matMenuTriggerFor]="rowUserMenu"
      [matMenuTriggerData]="{ $implicit: row.user }"
    ></x-user-pill>
  </ng-template>

  <mat-menu #rowUserMenu>
    <ng-template matMenuContent let-user>
      <a mat-menu-item [routerLink]="['/admin/users/detail', user.id]">View Customer</a>
    </ng-template>
  </mat-menu>

  <ng-template xDataColumnDef="orderNumber" let-row>
    <x-pill [matMenuTriggerFor]="rowOrderMenu" [matMenuTriggerData]="{ $implicit: row }">
      {{ row.orderNumber }}
    </x-pill>
  </ng-template>

  <mat-menu #rowOrderMenu>
    <ng-template matMenuContent let-voucher>
      <a mat-menu-item [routerLink]="['/admin/orders', voucher.orderId, 'detail']">View Order</a>
    </ng-template>
  </mat-menu>

  <ng-template xDataColumnDef="reason" let-row>
    {{ row.reason }}
  </ng-template>
  <ng-template xDataColumnDef="issuingUser" let-row>
    {{ row.issuingUser?.firstName }} {{ row.issuingUser?.lastName }}
  </ng-template>
  <ng-template xDataColumnDef="cancelled" let-row>
    <fa-icon
      *ngIf="row.cancelled"
      class="cancelled"
      icon="ban"
      matTooltip="{{ row.cancelledAt | datetime }}"
    ></fa-icon>
  </ng-template>
  <ng-template xDataColumnDef="cancelledAt" let-row>
    {{ row.cancelledAt | datetime }}
  </ng-template>
  <ng-template xDataColumnDef="cancelledReason" let-row>
    {{ row.cancelledReason }}
  </ng-template>
  <ng-template xDataColumnDef="createdAt" let-row>
    {{ row.createdAt | datetime }}
  </ng-template>
  <ng-template xDataColumnDef="updatedAt" let-row>
    {{ row.updatedAt | datetime }}
  </ng-template>

  <button
    *xDataAction="'balance'; let row; primary: true"
    x-data-button
    label="Voucher Balance"
    icon="dollar-sign"
    (click)="balance(row.id)"
  ></button>

  <button
    *xDataAction="'print'; let row"
    x-data-button
    label="Print Voucher"
    icon="print"
    (click)="print(row)"
  ></button>

  <button
    *xDataAction="'resend'; let row"
    x-data-button
    label="Resend"
    icon="paper-plane"
    (click)="resend(row)"
  ></button>

  <button
    *xDataAction="'cancel'; let row"
    x-data-button
    label="Cancel Voucher"
    icon="ban"
    color="warn"
    (click)="cancel(row)"
  ></button>

  <button
    x-data-button
    *xDataBulkAction="'resend'; let ids"
    label="Resend Vouchers"
    icon="paper-plane"
    color="primary"
    (click)="resendSelected()"
  ></button>
</x-data-table>

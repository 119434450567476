import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  VoucherBalanceDialogComponent,
  VoucherBalanceDialogData,
  VoucherBalanceDialogResult,
} from '../components/voucher-balance-dialog/voucher-balance-dialog.component';

@Injectable()
export class VoucherBalanceDialogService {
  constructor(private dialogs: MatDialog) {}

  openVoucherDialog(voucherId: number) {
    return this.dialogs.open<
      VoucherBalanceDialogComponent,
      VoucherBalanceDialogData,
      VoucherBalanceDialogResult
    >(VoucherBalanceDialogComponent, { data: { voucherId } });
  }
}

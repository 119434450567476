import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  VoucherDialogComponent,
  VoucherDialogData,
  VoucherDialogResult,
} from '../components/voucher-dialog/voucher-dialog.component';

@Injectable()
export class VoucherDialogService {
  constructor(private dialogs: MatDialog) {}

  openVoucherDialog() {
    return this.dialogs.open<VoucherDialogComponent, VoucherDialogData, VoucherDialogResult>(
      VoucherDialogComponent,
    );
  }
}

import { NgModule } from '@angular/core';
import { LocaleModule } from '@x/common/locale';
import { EcommerceAdminCoreModule } from '../core/ecommerce-admin-core.module';
import { VoucherBalanceDialogComponent } from './components/voucher-balance-dialog/voucher-balance-dialog.component';
import { VoucherCancelDialogComponent } from './components/voucher-cancel-dialog/voucher-cancel-dialog.component';
import { VoucherDialogComponent } from './components/voucher-dialog/voucher-dialog.component';
import { VoucherUserFilterFieldComponent } from './components/voucher-index/voucher-user-filter-field.component';
import { VoucherProgramDialogComponent } from './components/voucher-program-dialog/voucher-program-dialog.component';
import { VoucherProgramTableComponent } from './components/voucher-program-table/voucher-program-table.component';
import { VoucherTableComponent } from './components/voucher-table/voucher-table.component';
import { VOUCHER_PROGRAM_LANGUAGE } from './langauge/voucher-program.language';
import { VOUCHER_LANGUAGE } from './langauge/voucher.language';
import { VoucherBalanceDialogService } from './services/voucher-balance-dialog.service';
import { VoucherCancelDialogService } from './services/voucher-cancel-dialog.service';
import { VoucherDialogService } from './services/voucher-dialog.service';
import { VoucherProgramDialogService } from './services/voucher-program-dialog.service';

@NgModule({
  declarations: [
    VoucherDialogComponent,
    VoucherProgramDialogComponent,
    VoucherUserFilterFieldComponent,
    VoucherCancelDialogComponent,
    VoucherBalanceDialogComponent,
    VoucherTableComponent,
    VoucherProgramTableComponent,
  ],
  imports: [
    LocaleModule.forChild([VOUCHER_PROGRAM_LANGUAGE, VOUCHER_LANGUAGE]),
    EcommerceAdminCoreModule,
  ],
  providers: [
    VoucherProgramDialogService,
    VoucherDialogService,
    VoucherCancelDialogService,
    VoucherBalanceDialogService,
  ],
  exports: [VoucherUserFilterFieldComponent, VoucherTableComponent, VoucherProgramTableComponent],
})
export class VoucherSharedModule {}

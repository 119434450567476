import { LanguageDeclaration } from '@x/common/locale';

export const VOUCHER_LANGUAGE: LanguageDeclaration = {
  voucher: {
    column: {
      id: 'ID',
      code: 'Voucher Code',
      currency: 'Currency',
      value: 'Value',
      email: 'Customer Email',
      customer: 'Customer',
      reason: 'Reason',
      issuingUser: 'Issuing User',
      orderNumber: 'Order Number',
      cancelled: 'Cancelled',
      cancelledReason: 'Cancelled Reason',
      createdAt: 'Created At',
      updatedAt: 'Updated At',
    },
    sort: {
      id: 'ID',
      code: 'Code',
      value: 'Value',
    },
    action: {
      edit: 'Edit',
      resend: 'Resend Voucher',
      cancel: 'Cancel Voucher',
      balance: 'Voucher Balance',
      print: 'Print Voucher',
    },
    confirm: {
      resend: {
        title: 'Resend Voucher',
        message: `Do you want to send the voucher to it's corresponding owner again?`,
      },
      cancel: {
        title: 'Cancel Voucher',
        message: `Do you really want to cancel the voucher? This can not be undone.`,
      },
    },
  },
};

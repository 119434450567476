import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  VoucherCancelDialogComponent,
  VoucherCancelDialogData,
  VoucherCancelDialogResult,
} from '../components/voucher-cancel-dialog/voucher-cancel-dialog.component';

@Injectable()
export class VoucherCancelDialogService {
  constructor(private dialogs: MatDialog) {}

  openVoucherDialog(voucherId: number) {
    return this.dialogs.open<
      VoucherCancelDialogComponent,
      VoucherCancelDialogData,
      VoucherCancelDialogResult
    >(VoucherCancelDialogComponent, { data: { voucherId } });
  }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  VoucherProgramDialogComponent,
  VoucherProgramDialogData,
  VoucherProgramDialogResult,
} from '../components/voucher-program-dialog/voucher-program-dialog.component';

@Injectable()
export class VoucherProgramDialogService {
  constructor(private dialogs: MatDialog) {}

  openVoucherProgramCreateDialog(voucherProgramId?: number) {
    return this.dialogs.open<
      VoucherProgramDialogComponent,
      VoucherProgramDialogData,
      VoucherProgramDialogResult
    >(VoucherProgramDialogComponent, {
      data: { voucherProgramId },
    });
  }
}

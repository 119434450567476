import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WindowRef } from '@x/common/browser';
import { DataTableView } from '@x/common/data';
import { OperationSuccessState } from '@x/common/operation';
import { PromptDialogService } from '@x/dashboard/dialog';
import { IVoucherRowObject, VoucherService } from '@x/ecommerce/domain-client';
import { VoucherFilterInput } from '@x/schemas/ecommerce';
import { firstValueFrom } from 'rxjs';
import { VoucherBalanceDialogService } from '../../services/voucher-balance-dialog.service';
import { VoucherCancelDialogService } from '../../services/voucher-cancel-dialog.service';
import { VoucherDialogService } from '../../services/voucher-dialog.service';

@Component({
  selector: 'x-voucher-table',
  templateUrl: './voucher-table.component.html',
  styleUrls: ['./voucher-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class VoucherTableComponent {
  @Input()
  view: DataTableView<IVoucherRowObject, VoucherFilterInput, any, number>;

  constructor(
    private voucherService: VoucherService,
    private voucherDialogService: VoucherDialogService,
    private voucherCancelDialogService: VoucherCancelDialogService,
    private voucherBalanceDialogService: VoucherBalanceDialogService,
    private window: WindowRef,
    private snackbar: MatSnackBar,
    private promptDialog: PromptDialogService,
  ) {}

  createVoucher(): void {
    this.voucherDialogService
      .openVoucherDialog()
      .afterClosed()
      .subscribe((r) => {
        this.snackbar.open(`Voucher created`, 'Ok', { duration: 5000 });
        this.view.refresh();
      });
  }

  resend(row: IVoucherRowObject) {
    this.view.select(row);

    return this.view
      .mutateSelectedBatched(([voucherId]) => this.voucherService.resend(voucherId), {
        batchSize: 1,
      })
      .subscribe((result) => {
        if (result instanceof OperationSuccessState)
          this.snackbar.open(`Voucher resent`, 'Ok', { duration: 5000 });
      });
  }

  async resendSelected() {
    const result = await firstValueFrom(
      this.promptDialog.confirm({
        title: 'Resend Vouchers',
        message: 'Are you sure you want to resend all the selected vouchers?',
      }),
    );

    if (!result) return;

    this.view
      .mutateEachSelectedBatched((voucherId) => this.voucherService.resend(voucherId))
      .subscribe();
  }

  cancel(row: IVoucherRowObject) {
    this.voucherCancelDialogService
      .openVoucherDialog(row.id)
      .afterClosed()
      .subscribe((r) => {
        this.snackbar.open(`Voucher cancelled`, 'Ok', { duration: 5000 });
        this.view.refresh();
      });
  }

  balance(voucherId: number) {
    this.voucherBalanceDialogService
      .openVoucherDialog(voucherId)
      .afterClosed()
      .subscribe((r) => {
        this.view.refresh();
      });
  }

  print({ downloadUrl }: IVoucherRowObject) {
    this.window.openNewTab(downloadUrl);
  }
}

<x-data-table [view]="view" [selectable]="false">
  <ng-template xDataColumnDef="id" let-row>
    {{ row.id }}
  </ng-template>
  <ng-template xDataColumnDef="name" let-row>
    {{ row.name }}
  </ng-template>
  <ng-template xDataColumnDef="voucherProviderId" let-row>
    {{ row.voucherProviderId }}
  </ng-template>
  <ng-template xDataColumnDef="createdAt" let-row>
    {{ row.createdAt | datetime }}
  </ng-template>

  <button
    *xDataAction="'edit'; let row"
    x-data-button
    label="Edit Voucher Provider"
    icon="edit"
    (click)="edit(row)"
  ></button>

  <!-- <button
    *xDataAction="'archive'; let row"
    x-data-button
    label="Archive Voucher Provider"
    icon="trash"
    color="warn"
    (click)="archive(row)"
  ></button> -->
</x-data-table>

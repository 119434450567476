import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OperationObserverService } from '@x/common/operation';
import { VoucherService } from '@x/ecommerce/domain-client';

export interface VoucherBalanceDialogData {
  voucherId: number;
}

export interface VoucherBalanceDialogResult {}

@Component({
  selector: 'x-voucher-balance-dialog',
  templateUrl: './voucher-balance-dialog.component.html',
  styleUrls: ['./voucher-balance-dialog.component.scss'],
})
export class VoucherBalanceDialogComponent {
  operation$ = this.operationService.observe(this.voucherService.balance(this.data.voucherId));

  constructor(
    public dialog: MatDialogRef<VoucherBalanceDialogComponent>,
    private voucherService: VoucherService,
    private operationService: OperationObserverService,
    @Inject(MAT_DIALOG_DATA) public data: VoucherBalanceDialogData,
  ) {}
}

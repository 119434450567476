import { LanguageDeclaration } from '@x/common/locale';

export const VOUCHER_PROGRAM_LANGUAGE: LanguageDeclaration = {
  voucherProgram: {
    column: {
      id: 'ID',
      name: 'Program Name',
      createdAt: 'Ceated',
      voucherProviderId: 'Provider',
      archivedAt: 'Archived Date',
    },
    sort: {
      id: 'ID',
      name: 'Name',
    },
    action: {
      archive: 'Archive',
      edit: 'Edit',
    },
    confirm: {
      archive: {
        title: 'Archive Voucher Program',
        message: 'Do you want to archive the voucher program?',
      },
    },
  },
};

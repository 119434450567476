<h2 mat-dialog-title>Voucher Balance</h2>
<div mat-dialog-content *ngIf="operation$ | async as operation">
  <strong *ngIf="operation.isSuccessState()">
    {{ operation.data.balance.balance | money : operation.data.balance.currency }}
  </strong>
  <strong *ngIf="operation.isLoadingState()">Fetching voucher balance...</strong>
  <strong *ngIf="operation.isErrorState()">
    Unable to fetch voucher balance.
    <br />
    {{ operation.error.message }}
    <br />
    [{{ operation.error.code }}]
  </strong>
</div>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Close</button>
</div>

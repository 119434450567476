import type { IDataTableViewOptions } from '@x/common/data';

export const VOUCHER_TABLE_VIEW_OPTIONS: IDataTableViewOptions = {
  id: 'voucher_index',
  columns: [
    {
      id: 'id',
      title: 'ID',
      sortable: true,
    },
    {
      id: 'code',
      title: 'Voucher Code',
    },
    {
      id: 'value',
      title: 'Value',
    },
    {
      id: 'customer',
      title: 'Customer',
    },
    {
      id: 'reason',
      title: 'Reason',
    },
    {
      id: 'issuingUser',
      title: 'Issuing User',
    },
    {
      id: 'orderNumber',
      title: 'Order Number',
    },
    {
      id: 'cancelled',
      title: 'Cancelled',
    },
    {
      id: 'cancelledAt',
      title: 'Cancelled At',
    },
    {
      id: 'cancelledReason',
      title: 'Cancelled Reason',
    },
    {
      id: 'createdAt',
      title: 'Created At',
      sortable: true,
    },
    {
      id: 'updatedAt',
      title: 'Updated At',
      sortable: true,
    },
  ],
  sorts: [],
  defaultColumns: [
    'code',
    'value',
    'customer',
    'orderNumber',
    'reason',
    'issuingUser',
    'cancelled',
    'createdAt',
  ],
  page: { index: 0, size: 50 },
  pageSizes: [20, 50, 100, 200, 500],
  sort: {
    column: 'id',
    order: 'desc',
  },
};

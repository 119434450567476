import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VoucherProgramService } from '@x/ecommerce/domain-client';
import { ProductVariantAutocompleteDatasource } from '@x/ecommerce/domain-data';
import {
  CreateVoucherProgramInput,
  ProductVariantObject,
  UpdateVoucherProgramInput,
  VoucherProviderObject,
} from '@x/schemas/ecommerce';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface VoucherProgramDialogData {
  voucherProgramId?: number;
}

export interface VoucherProgramDialogResult {
  data?: { id: number };
}

@Component({
  selector: 'x-voucher-program-create-dialog',
  templateUrl: './voucher-program-dialog.component.html',
  styleUrls: ['./voucher-program-dialog.component.scss'],
  providers: [ProductVariantAutocompleteDatasource],
})
export class VoucherProgramDialogComponent implements OnInit {
  //unsubscribe
  private _destroy$ = new Subject<void>();

  voucherProviders: VoucherProviderObject[] = [];
  currencies: string[] = [];
  isUpdate: boolean = false;
  voucherProgramId: number;
  verb: string = 'Create';
  currency: string;

  voucherProgramForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    value: new UntypedFormControl('', [Validators.required, Validators.min(1)]),
    variants: new UntypedFormControl([], Validators.required),
    voucherProvider: new UntypedFormControl('', Validators.required),
    currency: new UntypedFormControl('', Validators.required),
  });

  constructor(
    public dialog: MatDialogRef<VoucherProgramDialogComponent>,
    private voucherProgramService: VoucherProgramService,
    public productVariantDataSource: ProductVariantAutocompleteDatasource,
    @Inject(MAT_DIALOG_DATA) public data: VoucherProgramDialogData,
  ) {}

  ngOnInit(): void {
    this.voucherProgramService.fetchVoucherProviders().subscribe((v: VoucherProviderObject[]) => {
      this.voucherProviders = v;
    });
    this.voucherProgramForm.get('voucherProvider')?.valueChanges.subscribe((v) => {
      this.currencies = this.voucherProviders.find((p) => p.id === v)?.currencies || [];
    });
    this.voucherProgramForm.get('currency')?.valueChanges.subscribe((c) => {
      this.currency = c;
    });

    this.isUpdate =
      this.data?.voucherProgramId !== undefined && this.data?.voucherProgramId !== null;

    if (this.data?.voucherProgramId) {
      this.voucherProgramService
        .fetchItem(this.data?.voucherProgramId)
        .pipe(
          tap((option) => {
            this.voucherProgramId = option.id;
            this.voucherProgramForm.get('voucherProvider')?.setValue(option.voucherProviderId);
            this.voucherProgramForm.get('name')?.setValue(option.name);
            this.voucherProgramForm.get('value')?.setValue(option.value);
            this.voucherProgramForm.get('variants')?.setValue(option.productVariants);
            this.voucherProgramForm.get('currency')?.setValue(option.currency);
            this.currency = option.currency;

            this.voucherProgramForm.get('voucherProvider')?.disable();
          }),
        )
        .subscribe();
      this.verb = 'Update';
    }
  }

  submit() {
    this.voucherProgramForm.updateValueAndValidity();

    if (this.voucherProgramForm.invalid) return;

    const form = this.voucherProgramForm.value;
    const variantsIds = form.variants.map((variant: ProductVariantObject) => variant.id);

    if (this.isUpdate) {
      const data: UpdateVoucherProgramInput = {
        id: this.voucherProgramId,
        name: form.name,
        productVariantIds: variantsIds,
        currency: form.currency,
        value: form.value,
      };
      this.voucherProgramService
        .update(data)
        .pipe(
          takeUntil(this._destroy$),
          tap((data) => this.dialog.close({ data })),
        )
        .subscribe();
    } else {
      const data: CreateVoucherProgramInput = {
        name: form.name,
        productVariantIds: variantsIds,
        currency: form.currency,
        value: form.value,
        voucherProviderId: form.voucherProvider || this.voucherProgramId,
      };

      this.voucherProgramService
        .create(data)
        .pipe(
          takeUntil(this._destroy$),
          tap((data) => this.dialog.close({ data })),
        )
        .subscribe();
    }
  }
}

import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VoucherService } from '@x/ecommerce/domain-client';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface VoucherCancelDialogData {
  voucherId: number;
}

export interface VoucherCancelDialogResult {
  data?: { id: number };
}

@Component({
  selector: 'x-voucher-cancel-dialog',
  templateUrl: './voucher-cancel-dialog.component.html',
  styleUrls: ['./voucher-cancel-dialog.component.scss'],
})
export class VoucherCancelDialogComponent {
  //unsubscribe
  private _destroy$ = new Subject<void>();

  voucherCancelForm = new UntypedFormGroup({
    reason: new UntypedFormControl('', Validators.required),
  });

  currency: string;

  constructor(
    public dialog: MatDialogRef<VoucherCancelDialogComponent>,
    private voucherService: VoucherService,
    @Inject(MAT_DIALOG_DATA) public data: VoucherCancelDialogData,
  ) {}

  submit() {
    this.voucherCancelForm.updateValueAndValidity();

    if (this.voucherCancelForm.invalid) return;

    const form = this.voucherCancelForm.value;

    this.voucherService
      .cancel(this.data.voucherId, form.reason)
      .pipe(
        takeUntil(this._destroy$),
        tap((data) => this.dialog.close({ data })),
      )
      .subscribe();
  }
}
